import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private authenticationService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.getCurrentUserValue;
        // let isAuth = this.authenticationService.isAuthenticated;
        // console.log(isAuth);
       // console.log(currentUser);
        //if (currentUser && isAuth) {
        const islogged = this.cookieService.get('loggedIn')
        //&& islogged === '1'
        if (currentUser && currentUser.userName && islogged == '1' ) {
                // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.userType) === -1 ) {
                // role not authorised so redirect to home page
                this.router.navigate(['dashboard']);
                return false;
            }
 
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}