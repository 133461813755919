import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser } from '../shared/models/user.model';
// import { map } from 'rxjs/internal/operators/map';
import { catchError, map } from 'rxjs/operators';
import { Response } from '../shared/common/response';
import {MessagingService} from "../shared/messaging.service";
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';
import { XFacStatusUserList } from '../shared/models/response.model';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  baseUrl: String;
  jwtHelper = new JwtHelperService();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  domain: string;
  staffPortalUrl: string;
  getStaffApiURL: String;
  getAuthURL: String;
  getBaseURL: String;
  clientAuthUrl: String;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
    
    //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    //let user ={'userName': currentUserCookie.userName,'userType': "1"};
    let user = new User(); //{'userName': currentUserCookie.userName,'userType': "1"};
    this.currentUserSubject = new BehaviorSubject<User>(user);
    user.userName = cookieService.get('userName');
    user.userType = parseInt(cookieService.get('userType'));
    if(user.userName){
      this.currentUserSubject = new BehaviorSubject<User>(user);
      this.currentUser = this.currentUserSubject.asObservable()
    }

    this.domain = AppConstants.getAuthDomain();
    this.staffPortalUrl = AppConstants.getStaffPortalURL();
    this.getStaffApiURL = AppConstants.getStaffApiURL(); 
    this.getAuthURL = AppConstants.getAuthURL(); 
    this.getBaseURL = AppConstants.getBaseURL(); 
    this.clientAuthUrl = AppConstants.getClientAuthURL();
    
  }

  public get getCurrentUserValue(): User {
  // console.log(this.currentUserSubject? this.currentUserSubject.value :null);
    return this.currentUserSubject.value ;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('currentUserToken'); 
    // Check whether the token is expired and return true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  Login(userName: String, password: String, operatingSystem: number) {
    return this.http.post<any>(this.baseUrl + '/Token/Login', { userName, password, operatingSystem }).pipe(map(data => {
      localStorage.removeItem('currentUserToken');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUser1');
      localStorage.removeItem('CurrentUserId');
      localStorage.removeItem('fireBaseToken');

      this.cookieService.delete('currentUserToken');
      this.cookieService.delete('userName'); 
      this.cookieService.delete('userType');
      this.cookieService.delete('currentUser');
      this.cookieService.delete('CurrentUserId');

      if (data.success) {
        if(data.userId)
        localStorage.setItem('LoginName', JSON.stringify(userName));
        var currentUser = {"userName":userName, "userType":1};       

        localStorage.setItem('currentUser1', JSON.stringify(currentUser));
        localStorage.setItem('CurrentUserId', JSON.stringify(data.response.userId));
        this.cookieService.set( 'CurrentUserId', data.response.userId, 365 );

      } else{
        return data;
      }
    return data;
    }));
  }

  verifyUser(accessCode: String,userId:String) {  
    return this.http.post<any>(this.clientAuthUrl + '/Token/VeryfyAccessCode',{ "userId": userId,"accessCode": accessCode} ).pipe(map(verifiedUser => {       
      // if (verifiedUser.token) { "userId": "7hzYMOgGrUqsRAAAzvw63w",  "accessCode": "1865"
      //   console.log(verifiedUser) 
      //   localStorage.setItem('currentUserToken', JSON.stringify(verifiedUser.token));
      //   //localStorage.setItem('currentUser', JSON.stringify(verifiedUser.authUser));   
      //   this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      //   localStorage.setItem('CurrentUserId', JSON.stringify(verifiedUser.authUser.id));
      // }

      if (verifiedUser.success && verifiedUser.response.token) {
        //console.log(verifiedUser) 

        let currentUser = localStorage.getItem('currentUser1');
        localStorage.setItem('currentUser', currentUser);
        this.cookieService.set( 'currentUser', currentUser );

        //let un = <string>userName;
        let currentUserCookie = JSON.parse(localStorage.getItem('currentUser'));
        this.cookieService.set( 'currentUser', currentUserCookie, 365 );
        this.cookieService.set( 'userName', currentUserCookie.userName, 365 );
        this.cookieService.set( 'userType', "1", 365 );

        //let CurrentUserId = JSON.parse(localStorage.getItem('CurrentUserId'));
        //this.cookieService.set( 'CurrentUserId', CurrentUserId, 365 );

        localStorage.setItem('currentUserToken', JSON.stringify(verifiedUser.response.token));
        this.cookieService.set( 'currentUserToken', verifiedUser.response.token, 365 );

       // this.messagingService.requestPermission(userId); 
        //localStorage.setItem('currentUser', JSON.stringify(verifiedUser.authUser));   

        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        let user = new User(); //{'userName': currentUserCookie.userName,'userType': "1"};
        user.userName = currentUserCookie.userName;
        user.userType = 1;
        
        this.currentUserSubject = new BehaviorSubject<User>(user);
        //this.cookieService.get('currentUserToken')

        //localStorage.setItem('CurrentUserId', JSON.stringify(verifiedUser.authUser.id));
      } else{
        return verifiedUser;
      }

      return verifiedUser;
    }));
  }

  appInstallation(firebase_token: String,UserId: String){
    //console.log(100000000000000000);
    //const params = new HttpParams().set('fireBaseToken', firebase_token).set('UserId', UserId).set('operatingSystem', '3').set('version', 'v1'); 
    return this.http.post<any>(this.baseUrl + '/AppInstallation',{'fireBaseToken': firebase_token,'UserId': UserId,'operatingSystem': '3','version': 'v1'} ).pipe(map(appinstallation => { 
      return appinstallation;  
    }));
  }
  

  logout() {
    // remove user from local storage to log user out
    //"userId": "string",
    //"fireBaseToken": "string"
    //console.log(555555555);

    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('CurrentUserId');
    localStorage.removeItem('fireBaseToken');
    localStorage.removeItem('currentUser1');
    localStorage.removeItem('menuList');
    localStorage.removeItem('businessId');
    sessionStorage.removeItem('currentPage');

    this.cookieService.delete('currentUserToken');
      this.cookieService.delete('userName'); 
      this.cookieService.delete('userType');   
      this.cookieService.delete('currentUser');
      this.cookieService.delete('CurrentUserId');
      this.cookieService.delete('firstName');
      this.cookieService.delete('lastName');
      this.cookieService.delete('companyCode');
      this.cookieService.delete('companyId');
      this.cookieService.delete('loggedIn');
      this.cookieService.deleteAll();

      this.cookieService.delete('currentUserToken','/', this.domain );
      this.cookieService.delete('userName','/', this.domain );
      this.cookieService.delete('userType','/', this.domain );
      this.cookieService.delete('currentUser','/', this.domain );
      this.cookieService.delete('CurrentUserId','/', this.domain );
      this.cookieService.delete('firstName','/', this.domain );
      this.cookieService.delete('lastName','/', this.domain );

      window.location.href = this.staffPortalUrl;



  // let firebase_token = localStorage.getItem('fireBaseToken');
  // //let UserId = JSON.parse(localStorage.getItem('CurrentUserId'));
  // let UserId = this.cookieService.get('CurrentUserId');
  // return this.http.post<any>(this.baseUrl + '/Token/Logout',{'fireBaseToken': firebase_token,'UserId': UserId} ).pipe(map(logout => { 
  //   localStorage.removeItem('currentUserToken');
  //   localStorage.removeItem('currentUser');
  //   localStorage.removeItem('CurrentUserId');
  //   localStorage.removeItem('fireBaseToken');
  //   localStorage.removeItem('currentUser1');

  //   this.cookieService.delete('currentUserToken');
  //   this.cookieService.delete('userName'); 
  //   this.cookieService.delete('userType');
  //   this.cookieService.delete('currentUser');
  //   this.cookieService.delete('CurrentUserId');
  //   //console.log(66666666);   

  //   return logout;  
  // }));
  
   
    //his.currentUserSubject.next(null);
  }
   
 currentUserClaims(){
   var tokenObj = localStorage.getItem('currentUserToken');
 }

 public get userToken(){
   return this.cookieService.get('currentUserToken');
   //return JSON.parse(localStorage.getItem('currentUserToken'));
 }

//  ResetPassword(userName: String) {
//   return this.http.post<any>(this.baseUrl + '/User/ForgetPassword', {userName}).pipe(map(data => {
    
//     }));
//   }

  ResetPassword(userName: string) {  
    const params = new HttpParams().set('userName', userName); 
    return this.http.post<any>(this.clientAuthUrl+'/User/ForgetPassword?userName='+userName,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public handleError(error) {
    return throwError(error);
  }

  getBusinessList(){
    return this.http.get<any>(this.getStaffApiURL + '/Businesss').pipe(map(data => {
      localStorage.removeItem('businessList');      
      if (data.success) {
        if(data.response)       
        localStorage.setItem('businessList', JSON.stringify(data.response));
      } else{
        return data;
      }
    return data;
    }));
  }

  viewPortalPermission(permission){
    let token = this.userToken;

    // console.log("====permission====000");
    // console.log(permission);

    let business_id = JSON.parse(localStorage.getItem('businessId'));
    const decoded = jwt_decode(token);

    // console.log("====decoded====");
    // console.log(decoded);
    // console.log("====decoded[permission]====");
    // console.log(decoded[permission]);
    
    if (decoded === undefined || decoded.scope=="") return null;
    let token_decoded = decoded;
    if (token_decoded[permission] === undefined || token_decoded[permission]=="") return null;

    // console.log("====permission====", permission);
    // console.log("====token_decoded====");
    // console.log(token_decoded);
    // console.log("====token_decoded[permission]====");
    // console.log(token_decoded[permission]);
    
    let menuList = JSON.parse(localStorage.getItem('businessList'));
    if (!menuList) return null;
    let item = menuList.find(x => x.id == business_id);
    if (!item) return null;
    //console.log("====decoded====2");
    if( typeof token_decoded == 'string') return null;
    let search = token_decoded[permission].split(",");

    // console.log("====item====");
    // console.log(item);
    // console.log("====search====");
    // console.log(search);
    // console.log(search.find(x => x.trim() == item.code));

    return search.find(x => parseInt(x.trim()) == item.code);
  }

  getUserList(userList){
    let userListString =""; 
    userList.forEach(user_id => {
      userListString = userListString +'userIdList='+user_id+'&'   //User/GetSelectedUsers
    });

    return this.http.get<XFacStatusUserList>(this.clientAuthUrl+'/User/GetSelectedUsers?'+userListString).pipe(
      catchError(this.handleError)
    );
  }

}


